import React from "react";
import PropTypes from "prop-types";

const Index = (props) => {
  return <div>INDEX</div>;
};

Index.propTypes = {};

export default Index;
